var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.show,"rounded":"sm"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.GET_STUDENT_COURSE_STATS(_vm.GET_ONE_COURSE.id).chapter_statistics)+" "),_c('course-progress-card',{attrs:{"data":_vm.GET_ONE_RELATION,"type":false,"series":Math.round(_vm.GET_STUDENT_COURSE_STATS(_vm.GET_ONE_COURSE.id).chapter_statistics || _vm.GET_ONE_COURSE.chapter_statistics),"time-spent":_vm.GET_ONE_COURSE.total_time_spent}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"list-group list-group-flush mt-2",attrs:{"tag":"ul"}},_vm._l((_vm.list),function(listItem){return _c('b-card-actions',{key:("key-" + (listItem.id)),staticClass:"m",attrs:{"title":listItem.title,"action-collapse":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-progress',{staticClass:"progress-bar-primary",staticStyle:{"min-width":"200px"},attrs:{"value":listItem.chapter_statistics,"max":"100","striped":"","variant":"primary","animated":"","show-value":""}})]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{attrs:{"responsive":"","items":listItem.lessons,"fields":_vm.fields},on:{"row-clicked":_vm.onOpenLesson},scopedSlots:_vm._u([{key:"cell(is_test)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.$t('courses.test') : _vm.$t('courses.content'))+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.progress_lesson_history)?_c('span',{staticClass:"d-flex flex-column align-items-center"},[(item.progress_lesson_history.is_passed)?[_c('div',{staticClass:"text-success"},[_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"22"}}),_vm._v(" "+_vm._s(_vm.$t('tests.passed'))+" ")],1)]:_vm._e(),(!item.progress_lesson_history.is_passed)?[_c('div',{staticClass:"text-danger"},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"22"}}),_vm._v(" "+_vm._s(_vm.$t('tests.failed'))+" ")],1)]:_vm._e()],2):_c('span',[_vm._v(" "+_vm._s(_vm.$t('courses.not_opened_yet'))+" ")])]}},{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(information)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-progress',{staticClass:"progress-bar-primary min-width-100",attrs:{"value":item.progress_lesson_history ? _vm.calculateLessonProgress(item) : 1,"max":"100","striped":"","variant":"primary","animated":"","show-value":""}})],1)]}},{key:"cell(last_visit)",fn:function(ref){
var item = ref.item;
return [(item.last_lesson_history)?_c('span',[_vm._v(_vm._s(_vm.$_dateTimeFormatter(item.last_lesson_history.created_date)))]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('courses.not_opened_yet'))+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center no-wrap"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon mr-50 p-25",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.onOpenLesson(data.item)}}},[_c('feather-icon',{attrs:{"size":"22","icon":"PlayIcon"}})],1)],1)]}}],null,true)})],1)],1)],1)}),1)])],1),_c('lesson-open-request-modal-vue',{attrs:{"allowed-to-view":_vm.allowed_to_view,"lesson-id":_vm.lessonId,"is-student-result-view":_vm.isStudentResultView}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }