<template>
  <b-row>

    <b-col cols="12">
      <b-overlay
        id="overlay-background"
        :show="show"
        rounded="sm"
      >
        <b-row>
          <b-col
            cols="12"
          >
            {{ GET_STUDENT_COURSE_STATS(GET_ONE_COURSE.id).chapter_statistics }}
            <course-progress-card
              :data="GET_ONE_RELATION"
              :type="false"
              :series="Math.round(GET_STUDENT_COURSE_STATS(GET_ONE_COURSE.id).chapter_statistics || GET_ONE_COURSE.chapter_statistics)"
              :time-spent="GET_ONE_COURSE.total_time_spent"
            />
          </b-col>
          <b-col cols="12">
            <div
              class="list-group list-group-flush mt-2"
              tag="ul"
            >

              <b-card-actions
                v-for="listItem in list"
                :key="`key-${listItem.id}`"
                :title="listItem.title"
                action-collapse
                class="m"
              >

                <template #header>
                  <b-progress
                    :value="listItem.chapter_statistics"
                    max="100"
                    striped
                    class="progress-bar-primary"
                    style="min-width: 200px;"
                    variant="primary"
                    animated
                    show-value
                  />
                </template>
                <b-row>
                  <b-col cols="12">
                    <b-table
                      responsive
                      :items="listItem.lessons"
                      :fields="fields"
                      @row-clicked="onOpenLesson"
                    >
                      <template #cell(is_test)="{value}">
                        {{ value ? $t('courses.test') : $t('courses.content') }}
                      </template>
                      <template #cell(status)="{ item }">
                        <span
                          v-if="item.progress_lesson_history"
                          class="d-flex flex-column align-items-center"
                        >
                          <template v-if="item.progress_lesson_history.is_passed">
                            <div
                              class="text-success"
                            >
                              <feather-icon
                                icon="CheckIcon"
                                size="22"
                              />
                              {{ $t('tests.passed') }}
                            </div>
                          </template>
                          <template v-if="!item.progress_lesson_history.is_passed">
                            <div class="text-danger">
                              <feather-icon
                                icon="XIcon"
                                size="22"
                              />
                              {{ $t('tests.failed') }}
                            </div>
                          </template>
                        </span>
                        <span v-else> {{ $t('courses.not_opened_yet') }} </span>
                      </template>
                      <template #head()="{label}">
                        {{ $t(label) }}
                      </template>
                      <template #cell(information)="{ item }">
                        <div class="d-flex align-items-center justify-content-center">
                          <b-progress
                            :value="item.progress_lesson_history ? calculateLessonProgress(item) : 1"
                            max="100"
                            striped
                            class="progress-bar-primary min-width-100"
                            variant="primary"
                            animated
                            show-value
                          />
                        </div>
                      </template>
                      <template #cell(last_visit)="{ item }">
                        <span v-if="item.last_lesson_history">{{ $_dateTimeFormatter(item.last_lesson_history.created_date) }}</span>
                        <span v-else> {{ $t('courses.not_opened_yet') }} </span>
                      </template>
                      <template
                        #cell(actions)="data"
                      >
                        <div class="d-flex align-items-center no-wrap">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="success"
                            class="btn-icon mr-50 p-25"
                            size="sm"
                            @click="onOpenLesson(data.item)"
                          >
                            <feather-icon
                              size="22"
                              icon="PlayIcon"
                            />
                          </b-button>
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-card-actions>
              <!-- <div
            v-for="listItem in list"
            :key="`key-${listItem.id}`"
          >
            <b-list-group-item
              v-b-toggle="`coll${listItem.id}`"
              tag="li"
              class="drag-style"
            >
              <div class="ml-25">
                <b-card-text class="font-weight-bold mb-0 d-flex drag-text">
                  <h5 class="text-uppercase p-0 m-0">
                    {{ listItem.title }}
                  </h5>
                </b-card-text>
              </div>
            </b-list-group-item>
            <b-collapse
              :id="`coll${listItem.id}`"
              :visible="true"
            >
              <b-card class="mx-2">
                <b-row>
                  <b-col cols="12">
                    <b-table
                      responsive
                      :items="listItem.lessons"
                      :fields="fields"
                      @row-clicked="onOpenLesson"
                    >
                      <template #cell(is_test)="{value}">
                        {{ value ? $t('courses.test') : $t('courses.content') }}
                      </template>
                      <template #cell(status)>
                        <span class="text-danger d-flex flex-column align-items-center">
                          <feather-icon
                            icon="XIcon"
                            size="22"
                          />
                          failed
                        </span>
                      </template>
                      <template #head()="{label}">
                        {{ $t(label) }}
                      </template>
                      <template #cell(information)>
                        <div class="d-flex align-items-center justify-content-center">
                          <b-progress
                            :value="35"
                            max="100"
                            striped
                            class="progress-bar-primary min-width-100"
                            variant="primary"
                            animated
                            show-value
                          />
                        </div>
                      </template>
                      <template #cell(last_access)>
                        <span>18.12.2022</span>
                      </template>
                      <template
                        #cell(actions)="data"
                      >
                        <div class="d-flex align-items-center no-wrap">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="success"
                            class="btn-icon mr-50 p-25"
                            size="sm"
                            @click="onOpenLesson(data.item)"
                          >
                            <feather-icon
                              size="22"
                              icon="PlayIcon"
                            />
                          </b-button>
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </div> -->
            </div>
          </b-col>
        </b-row>

        <lesson-open-request-modal-vue
          :allowed-to-view="allowed_to_view"
          :lesson-id="lessonId"
          :is-student-result-view="isStudentResultView"
        />
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  // BBadge,
  // BListGroupItem,
  // BCardText,
  // BCollapse,
  VBToggle,
  // BCard,
  BTable,
  BProgress,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import courseProgressCard from '../components/courseProgressCard.vue'
import LessonOpenRequestModalVue from './components/LessonOpenRequestModal.vue'
import extra from '@/mixins/extra'

export default {
  components: {
    BRow,
    BCol,
    // BCardText,
    // BListGroupItem,
    // BCard,
    BCardActions,
    BTable,
    // BBadge,
    BButton,
    BProgress,
    BOverlay,
    // BCollapse,
    LessonOpenRequestModalVue,
    courseProgressCard,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [extra],
  data() {
    return {
      isStudentResultView: false,
      windowTop: null,
      lessonData: {},
      editedTheme: {},
      lessonParentId: null,
      isEdit: false,
      isChapter: true,
      table: [],
      chapter: null,
      card: {},
      dataToEdit: {},
      lessonId: {},
      allowed_to_view: true,
      show: true,
      fields: [
        {
          label: 'courses.lesson_title',
          key: 'title',
          thStyle: { width: '30%' },
        },
        {
          label: 'courses.type',
          key: 'is_test',
        },
        {
          label: 'users.status',
          key: 'status',
          thClass: 'text-center',
        },
        {
          label: 'others.information',
          key: 'information',
          thClass: 'text-center',
        },
        {
          label: 'tests.last_activity',
          key: 'last_visit',
        },
        {
          label: 'courses.time_spent',
          key: 'total_time_spent',
        },
        {
          label: 'general.actions',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_ONE_COURSE', 'GET_COURSE_CHAPTERS', 'GET_ONE_RELATION']),
    ...mapGetters('contacts', ['GET_STUDENT_COURSE_STATS']),
    list: {
      get() {
        return this.GET_COURSE_CHAPTERS
      },
      set(val) {
        this.SET_CHAPTERS(val)
      },
    },
    getCourseStatistics() {
      return [Math.round(this.GET_ONE_COURSE.chapter_statistics), 100 - Math.round(this.GET_ONE_COURSE.chapter_statistics)]
    },
  },
  async mounted() {
    if (this.$route.path.split('/')[1] === 'student') {
      this.isStudentResultView = true
    }
    window.addEventListener('scroll', this.onScroll)
    const rootEle = document.documentElement
    const scrollPosition = JSON.parse(sessionStorage.getItem('scrollPosition'))
    if (this.isStudentResultView) {
      await this.FETCH_ONE_COURSE_FOR_ADMIN({ id: this.$route.params.id, student_id: this.$route.params.studentId })
      this.FETCH_ONE_CONTACT(this.$route.params.studentId)
        .then(res => {
          this.list = this.GET_COURSE_CHAPTERS
          this.updateBreadcrumb(res.first_name, res.get_user)
        })
    } else {
      await this.FETCH_ONE_COURSE({id:this.$route.params.id}).then(() => {
        this.updateBreadcrumb()
        if (this.$route.params.formType) {
          this.list = this.GET_COURSE_CHAPTERS.filter(el => el.form_type === Number(this.$route.params.formType))
        } else {
          this.list = this.GET_COURSE_CHAPTERS
        }
      }).catch(e => {
          if (e.response.status === 403) {
            this.$_errorToast(this.$t('courses.access_closed'))
            this.$router.go(-1)
          }
        })
    }
    this.FETCH_ONE_RELATION(this.$route.params.relationId)
    this.show = false
    if (scrollPosition?.id === this.$route.params.id) {
      rootEle.scrollTo({
        top: scrollPosition.position,
        behavior: 'smooth',
      })
    }
  },
  beforeDestroy() {
    this.SET_CHAPTERS([])
    this.SET_ONE_COURSE({})
    this.SET_ONE_RELATION({})
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    ...mapActions('courses', ['FETCH_ONE_COURSE', 'FETCH_ONE_RELATION', 'FETCH_LESSON', 'FETCH_ONE_COURSE_FOR_ADMIN']),
    ...mapActions('contacts', ['FETCH_ONE_CONTACT']),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    ...mapMutations('courses', ['SET_CHAPTERS', 'SET_ONE_COURSE', 'SET_ONE_RELATION']),
    updateBreadcrumb(val, id) {
      const [first, second] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      if (val) {
        first.text = val
        first.to = `/users/${id}/info`
      }
      second.text = this.GET_ONE_COURSE.title
      this.UPDATE_BREADCRUMB([first, second])
    },
    calculateLessonProgress(lesson) {
      if (lesson.is_test) {
        return lesson.progress_lesson_history.total_score ? this.calculateTestProgress(lesson) : 0
      }
      return 100
    },
    calculateTestProgress(lesson) {
      return (lesson.progress_lesson_history.total_score / lesson.progress_lesson_history.max_score) * 100
    },
    onOpenLesson(el) {
      this.FETCH_LESSON(el.id).then(() => {
        this.allowed_to_view = true
      }).catch(err => {
        if (err.response.status === 406) {
          this.allowed_to_view = false
        }
      }).finally(() => {
        this.lessonId = el
        this.$nextTick(() => {
          this.$bvModal.show('lessonOpenModal')
        })
      })
    },
    onScroll() {
      this.windowTop = window.top.scrollY /* or: e.target.documentElement.scrollTop */
      sessionStorage.setItem('scrollPosition', JSON.stringify({ id: this.$route.params.id, position: this.windowTop }))
    },
  },
}
</script>

<style>
.drag-style {
      padding: 1rem 2.8rem 1rem 1rem;
      margin-bottom: 1rem;
}
.drag-text {
    justify-content: center;
    align-items: center;
}
.min-width-100 {
  min-width: 120px;
}

[dir] .card {
    margin-bottom: 1.5rem;
}
</style>
