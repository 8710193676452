<template>
  <div>
    <b-modal
      id="lessonOpenModal"
      centered
      size="lg"
      no-close-on-backdrop
      :ok-title="$t('general.start')"
      :ok-disabled="disabled"
      :cancel-title="$t('general.cancel')"
      :title="lessonId.title"
      @ok="okClick"
      @show="onShow"
      @hidden="hidden"
    >
      <!-- <pre>{{ GET_ONE_LESSON_VIEW_HISTORY }}</pre> -->
      <b-row>
        <b-col
          cols="12"
          class="mt-2"
        >
          <h6>{{ $t('users.course_title') }}:</h6>
          <span>{{ GET_ONE_LESSON_VIEW_HISTORY.course_title }}</span>
        </b-col>
        <b-col
          cols="5"
          class="mt-2"
        >
          <h6>{{ $t('users.theme_title') }}:</h6>
          <span>{{ GET_ONE_LESSON_VIEW_HISTORY.chapter_title }}</span>
        </b-col>
        <b-col
          cols="3"
          class="mt-2"
        >
          <h6>{{ $t('users.lesson_title') }}:</h6>
          <span>{{ lessonId.title }}</span>
        </b-col>
        <b-col
          cols="3"
          class="mt-2"
        >
          <h6>{{ $t('courses.time_spent') }}:</h6>
          <span>{{ GET_ONE_LESSON_VIEW_HISTORY.spend_time }}</span>
        </b-col>
        <template v-if="GET_ONE_LESSON_VIEW_HISTORY.options">
          <b-col
            cols="3"
            class="mt-2"
          >
            <h6>{{ $t('tests.attempts') }}:</h6>
            <span v-if="GET_ONE_LESSON_VIEW_HISTORY.options.attempts">{{ GET_ONE_LESSON_VIEW_HISTORY.options.attempts - GET_ONE_LESSON_VIEW_HISTORY.lesson_history.length }}</span>
            <span v-else>{{ $t('tests.infinity') }}</span>
          </b-col>
          <b-col
            cols="3"
            class="mt-2"
          >
            <h6>{{ $t('tests.passing_score') }}:</h6>
            <span v-if="GET_ONE_LESSON_VIEW_HISTORY.options.passing_score">{{ GET_ONE_LESSON_VIEW_HISTORY.options.passing_score }}</span>
            <span v-else>0</span>
          </b-col>
          <b-col
            cols="3"
            class="mt-2"
          >
            <h6>{{ $t('tests.time_min') }}:</h6>
            <span v-if="GET_ONE_LESSON_VIEW_HISTORY.options.time">{{ GET_ONE_LESSON_VIEW_HISTORY.options.time }}</span>
            <span v-else>{{ $t('tests.infinity') }}</span>
          </b-col>
        </template>
        <b-col
          cols="12"
          class="mt-2"
        >
          <h6>{{ $t('general.description') }}:</h6>
          <span>{{ GET_ONE_LESSON_VIEW_HISTORY.description }}</span>
        </b-col>
        <b-col
          class="mt-5"
          cols="12"
        >
          <h4>История просмотра курса</h4>
        </b-col>
      </b-row>

      <b-table
        hover
        class="mt-2"
        responsive
        show-empty
        sticky-header="200px"
        :empty-text="$t('general.no_data_to_view')"
        :fields="lessonId.is_test ? fields : fields.slice(0, -1)"
        :items="GET_ONE_LESSON_VIEW_HISTORY.lesson_history"
        style-class="slots_text_align vgt-table bordered"
        @row-clicked="openHistory"
      >
        <template #head()="{ label }">
          {{ $t(label) }}
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(actions)="{ item }">
          <b-button
            class="p-50"
            variant="info"
            @click="openHistory(item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BTable, BButton,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BButton,
  },
  props: {
    lessonId: {
      type: Object,
      required: true,
    },
    allowedToView: {
      type: Boolean,
      required: false,
    },
    isStudentResultView: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      fields: [
        {
          label: 'users.amount',
          key: 'index',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'courses.time_spent',
          key: 'overall_time',
          tdClass: 'text-center',
          thClass: 'text-center',
          formatter: date => { if (date) { return date } return '00:00' },
        },
        {
          label: 'users.watch_time',
          key: 'start_time',
          tdClass: 'text-center',
          thClass: 'text-center',
          formatter: date => new Date(date).toLocaleString(),
        },
        {
          label: 'general.actions',
          key: 'actions',
        },
      ],
      items: [
        {
          index: 1,
          time: '00:01:11',
          date: '18.12.2022',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_LESSON_VIEW_HISTORY', 'GET_ONE_LESSON_VIEW_HISTORY']),
    disabled() {
      // if (this.isStudentResultView) {
      //   return this.isStudentResultView
      // }
      return !this.allowedToView
    },
  },
  methods: {
    ...mapActions('courses', [
      'FETCH_LESSON_VIEW_HISTORY',
      'CREATE_LESSON_VIEW_HISTORY',
      'FETCH_ONE_LESSON_VIEW_HISTORY',
      'FETCH_ONE_LESSON_VIEW_HISTORY_FOR_ADMIN',
    ]),
    ...mapMutations('courses', ['SET_ONE_LESSON_VIEW_HISTORY']),
    onShow() {
      if (this.isStudentResultView) {
        this.FETCH_ONE_LESSON_VIEW_HISTORY_FOR_ADMIN({ id: this.lessonId.id, student_id: this.$route.params.studentId })
      } else {
        this.FETCH_ONE_LESSON_VIEW_HISTORY(this.lessonId.id)
      }
    },
    okClick() {
      if (this.allowedToView) {
        this.onStart()
      } else {
        this.$_errorToast(this.$t('courses.not_allowed_to_view'))
      }
    },
    openHistory(item) {
      if (this.lessonId.is_test) {
        console.log(item)
        this.$router.push({ name: 'test-report-view', params: { id: item.id } })
      }
    },
    hidden() {
      this.SET_ONE_LESSON_VIEW_HISTORY({
        lesson_history: [],
      })
    },
    onStart() {
      if (this.lessonId.is_test) {
        console.log(JSON.stringify(this.lessonId, null, 2))
        // this.$router.push(`/lessons/${this.lessonId.id}/view`)
        this.$router.push({ name: 'lesson-view', params: { id: this.lessonId.id } })
      } else {
        this.$router.push(
          `/course/${this.$route.params.id}/view/${this.lessonId.id}`,
        )
      }
    },
  },
}
</script>

<style>
</style>
